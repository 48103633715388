import React from 'react';
import styled from 'styled-components';
import { useSettingsContext } from '../../context/SettingsContext';
import { getRandomFromArray } from '../../utils/getRandomFromArray';

const Wrapper = styled.div`
  text-align: center;
`;

const Actions = () => {
  const { actions } = useSettingsContext();
  const action = getRandomFromArray(actions);
  return (
    <Wrapper>
      <h1>{action}</h1>
      <p>...die begint</p>
    </Wrapper>
  );
};

export default Actions;
