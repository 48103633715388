const repeat = (func, times) => {
  func();
  times && --times && repeat(func, times);
};

export const getRandomFromArray = <Type extends unknown>(
  array: Type[],
  inputWeights?: number[],
) => {
  const weights = Array(array.length).fill(1);
  if (inputWeights) {
    inputWeights.forEach((weight, index) => {
      weights[index] = weight;
    });
  }

  const adjustedArray = [];

  weights.forEach((weight, index) => {
    repeat(() => {
      adjustedArray.push(array[index])
    },weight)
  });

  const index = Math.floor(Math.random() * adjustedArray.length);
  return adjustedArray[index];
};
