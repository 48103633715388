import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { getRandomFromArray } from '../../utils/getRandomFromArray';
import Button from '../Button';

const Wrapper = styled.div`
  text-align: center;
`;

const coins = ['Euro', 'Gulden', 'Frank', 'Pond'];

const CoinFlip = () => {
  const [isHeads, setIsHeads] = useState<null | boolean>(null);
  const coin = getRandomFromArray(coins);

  const flip = useCallback(() => {
    setIsHeads(Math.random() > 0.5);
  }, []);

  if (isHeads !== null) {
    return <Wrapper>{isHeads ? <h1>Kop</h1> : <h1>Munt</h1>}</Wrapper>;
  }

  return (
    <Wrapper>
      <h1>Kop of munt?</h1>
      <Button onClick={flip}>Gooi een {coin} op</Button>
    </Wrapper>
  );
};

export default CoinFlip;
