import Color from 'color';
import { randomInRange } from './randomInRange';

const minLighten = 0;
const maxLighten = 0.1;
const minSaturate = 0.6;
const maxSaturate = 0.9;
const minHueOffset = -90;
const maxHueOffset = 90;

export const secondaryColor = (colorString: string) => {
  const color = Color(colorString);

  const lighten = randomInRange(minLighten, maxLighten);
  const saturate = randomInRange(minSaturate, maxSaturate);
  const rotate = Math.round(randomInRange(minHueOffset, maxHueOffset));

  const newColor = color.rotate(rotate).lighten(lighten).saturate(saturate)

  return newColor.hex();
};
