import React from 'react';
import styled from 'styled-components';
import { useSettingsContext } from '../../context/SettingsContext';
import { getRandomFromArray } from '../../utils/getRandomFromArray';

const Wrapper = styled.div`
  text-align: center;
`;

const UserDecides = () => {
  const { users, extraDeciders } = useSettingsContext();
  const user = getRandomFromArray([...users, ...extraDeciders]);

  return (
    <Wrapper>
      <h1>{user} beslist</h1>
      <p>{user} mag kiezen wie begint</p>
    </Wrapper>
  );
};

export default UserDecides;
