import React from 'react';
import styled from 'styled-components';
import { useSettingsContext } from '../../context/SettingsContext';
import { getRandomFromArray } from '../../utils/getRandomFromArray';

const Wrapper = styled.div`
  text-align: center;
`;

const Statements = () => {
  const {statements} = useSettingsContext()
  const statement = getRandomFromArray(statements);
  
  return (
    <Wrapper>
      <h1>{statement}</h1>
      <p>...die begint</p>
    </Wrapper>
  );
};

export default Statements;
