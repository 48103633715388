import React, { useEffect, useState } from 'react';
import Actions from '../components/deciders/Actions';
import CoinFlip from '../components/deciders/CoinFlip';
import Roulette from '../components/deciders/Roulette';
import Statements from '../components/deciders/Statements';
import User from '../components/deciders/User';
import UserDecides from '../components/deciders/UserDecides';
import Page from '../components/Page';
import { getRandomFromArray } from '../utils/getRandomFromArray';
import { Helmet } from 'react-helmet';

const options = [CoinFlip, User, UserDecides, Actions, Statements, Roulette];
const optionsIndexes = options.map((component, index) => index);
const weights = [2, 3, 3, 4, 6, 1];

const Decider = () => {
  const [componentIndex, setComponentIndex] = useState(getRandomFromArray(optionsIndexes, weights));

  useEffect(() => {
    const setNewComponent = () => {
      setComponentIndex(getRandomFromArray(optionsIndexes, weights));
    };

    window.addEventListener('focus', setNewComponent);

    return () => {
      window.removeEventListener('focus', setNewComponent);
    };
  }, []);

  const Component = options[componentIndex];

  return (
    <Page key={Math.random()}>
      <Component />
    </Page>
  );
};

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Wie Mag Beginnen?</title>
        <meta name="description" content="Wie mag beginnen? Weet het in een paar seconden." />
      </Helmet>
      <Decider />
    </>
  );
};

export default IndexPage;
