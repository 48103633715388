import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getRandomColor } from '../utils/getRandomColor';
import { randomInRange } from '../utils/randomInRange';
import { secondaryColor } from '../utils/secondaryColor';

const BACKGROUND_ANIMATION_DURATION = 15000; //ms

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const StyledPage = styled.div<{
  color1: string;
  color2: string;
  direction: number;
  isMounted: boolean;
}>`
  border: 2rem solid white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-image: ${({ color1, color2, direction }) =>
    `linear-gradient(${direction}deg, ${color1}, ${color2});`};
  height: 100vh;
  background-size: 400% 400%;
  animation: ${gradient} ${BACKGROUND_ANIMATION_DURATION}ms ease infinite;
  opacity: ${({ isMounted }) => (isMounted ? 1 : 0)};
  transition: 200ms ease-out opacity;
`;

const Content = styled.div<{ isMounted: boolean }>`
  opacity: ${({ isMounted }) => (isMounted ? 1 : 0)};
  transition: 1100ms cubic-bezier(1,.32,.63,1.75) opacity, 1100ms cubic-bezier(1,.32,.63,1.75) transform;
  transform: ${({ isMounted }) => (isMounted ? `scale(1)` : `scale(0)`)};
`;

const Page: React.FC<{ color1?: string; color2?: string; direction?: number }> = React.memo(
  ({
    children,
    color1 = getRandomColor(),
    color2 = secondaryColor(color1),
    direction = randomInRange(-45, 45),
  }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      setIsMounted(true);
    }, []);

    if (!isMounted) {
      return (
        <StyledPage color1={color1} color2={color2} direction={direction} isMounted={false}>
          <Content isMounted={false}></Content>
        </StyledPage>
      );
    }

    return (
      <StyledPage color1={color1} color2={color2} direction={direction} isMounted={isMounted}>
        <Content isMounted={isMounted}>{children}</Content>
      </StyledPage>
    );
  },
);

export default Page;
