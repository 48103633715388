import React from 'react';
import styled from 'styled-components';
import { useSettingsContext } from '../../context/SettingsContext';
import { getRandomFromArray } from '../../utils/getRandomFromArray';

const Wrapper = styled.div`
  text-align: center;
`;

const User = () => {
  const { users } = useSettingsContext();
  const user = getRandomFromArray(users)

  return (
    <Wrapper>
      <h1>{user}</h1>
      <p>
        {user} begint, geen gezeur, geen gemaar, {user} begint.
      </p>
    </Wrapper>
  );
};

export default User;
