import React, { useCallback, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { randomInRange } from '../../utils/randomInRange';
import Button from '../Button';

const Wrapper = styled.div`
  text-align: center;
`;

const Disc = styled.div<{ degrees: number; startSpin: boolean }>`
  height: 50vw;
  width: 50vw;
  background: black;
  border-radius: 50%;
  position: relative;
  transform: ${({ degrees, startSpin }) =>
    startSpin ? `rotate(${degrees + 360 * 12}deg)` : undefined};
  transition: transform 12s cubic-bezier(0.28, 0, 0, 0.98);
  opacity: 0.7;

  &::after {
    position: absolute;
    content: '';
    top: 1;
    left: Calc(50% - 1vw);
    border-radius: 2vw;
    width: 2vw;
    height: 25vw;
    background: #e63c3c;
  }
`;

const Roulette = () => {
  const [degrees, setDegrees] = useState<null | number>(null);
  const [startSpin, setStartSpin] = useState(false);

  const rotate = useCallback(() => {
    setDegrees(randomInRange(0, 360));
    setTimeout(() => {
      setStartSpin(true);
    }, 0);
  }, []);

  if (degrees !== null) {
    return (
      <Wrapper>
        <Disc degrees={degrees} startSpin={startSpin} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1>De wijzer bepaalt, leg de telefoon neer en klik start</h1>
      <Button onClick={rotate}>Start</Button>
    </Wrapper>
  );
};

export default Roulette;
