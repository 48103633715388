import Color from 'color';
import { randomInRange } from './randomInRange';
const minHue = 0;
const maxHue = 360;
const minSaturation = 80;
const maxSaturation = 90;
const minLightness = 50;
const maxLightness = 85;


export const getRandomColor = () => {
  const hue = randomInRange(minHue, maxHue);
  const saturation = randomInRange(minSaturation, maxSaturation);
  const lightness = randomInRange(minLightness, maxLightness);

  const color = Color({
    h: hue,
    s: saturation,
    l: lightness,
  }).hex();

  return color;
};
