import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color:#333;
  opacity: 0.8;
  color: white;
  padding: 12px 30px;
  border: 0;
  font-size: 1.3rem;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  cursor: pointer;
  transition: ease opacity 250ms;
  &:hover {
    opacity: 1;
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;
interface ButtonProps {
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, ...rest }) => {
  return (
    <StyledButton onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
